// Generated by Framer (66beb72)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/TbmJG1iM0";

const cycleOrder = ["Mk9iWf33O"];

const serializationHash = "framer-tZYtd"

const variantClassNames = {Mk9iWf33O: "framer-v-zthm30"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, text, width, ...props}) => { return {...props, bukneBX_6: link ?? props.bukneBX_6, dmQqSHJMk: text ?? props.dmQqSHJMk ?? "New version available. Download now!"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, dmQqSHJMk, bukneBX_6, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Mk9iWf33O", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={bukneBX_6}><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-zthm30", className, classNames)} framer-1s8wl5b`} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Mk9iWf33O"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgb(28, 36, 76)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(6, 10, 30)", borderBottomLeftRadius: 32, borderBottomRightRadius: 32, borderTopLeftRadius: 32, borderTopRightRadius: 32, ...style}}><motion.div className={"framer-8cp4m5"} data-framer-name={"Overlay"} layoutDependency={layoutDependency} layoutId={"MMpi92EcF"} style={{boxShadow: "inset 0px -10px 14px 0px rgb(16, 22, 54)"}}/><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-j0v3gb"} data-styles-preset={"TbmJG1iM0"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-7a9e47d0-5397-40af-b396-a04512fe1d9e, rgb(255, 255, 255)))"}}>New version available. Download now!</motion.p></React.Fragment>} className={"framer-k2p49z"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"c7jsIhlgC"} style={{"--extracted-r6o4lv": "var(--token-7a9e47d0-5397-40af-b396-a04512fe1d9e, rgb(255, 255, 255))", "--framer-paragraph-spacing": "0px"}} text={dmQqSHJMk} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-tZYtd.framer-1s8wl5b, .framer-tZYtd .framer-1s8wl5b { display: block; }", ".framer-tZYtd.framer-zthm30 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 4px 17px 4px 17px; position: relative; text-decoration: none; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-tZYtd .framer-8cp4m5 { align-content: center; align-items: center; bottom: 0px; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 100%; justify-content: center; left: 0px; overflow: visible; padding: 0px; position: absolute; right: 0px; z-index: 1; }", ".framer-tZYtd .framer-k2p49z { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-tZYtd.framer-zthm30, .framer-tZYtd .framer-8cp4m5 { gap: 0px; } .framer-tZYtd.framer-zthm30 > *, .framer-tZYtd .framer-8cp4m5 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-tZYtd.framer-zthm30 > :first-child, .framer-tZYtd .framer-8cp4m5 > :first-child { margin-left: 0px; } .framer-tZYtd.framer-zthm30 > :last-child, .framer-tZYtd .framer-8cp4m5 > :last-child { margin-right: 0px; } }", ...sharedStyle.css, ".framer-tZYtd[data-border=\"true\"]::after, .framer-tZYtd [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 301
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"dmQqSHJMk":"text","bukneBX_6":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameraFdikaJAj: React.ComponentType<Props> = withCSS(Component, css, "framer-tZYtd") as typeof Component;
export default FrameraFdikaJAj;

FrameraFdikaJAj.displayName = "Title";

FrameraFdikaJAj.defaultProps = {height: 32, width: 301};

addPropertyControls(FrameraFdikaJAj, {dmQqSHJMk: {defaultValue: "New version available. Download now!", displayTextArea: false, title: "Text", type: ControlType.String}, bukneBX_6: {title: "Link", type: ControlType.Link}} as any)

addFonts(FrameraFdikaJAj, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})